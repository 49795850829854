<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a v-on="on" @click="refresh" href="#/users">
        <v-icon small>
          mdi-pencil
        </v-icon>
      </a>
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">{{ model.name }} {{ model.surname }}</span>
        </v-card-title>
        <v-card-text>
          <h3>User authorizations</h3>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-switch v-model="groups[1]" label="Analytics"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="groups[2]"
                  label="Search settings"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch v-model="groups[3]" label="Data"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch v-model="groups[4]" label="Developer"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="groups[5]"
                  label="Access management"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import {
  PULL_USER,
  PULL_USERS,
  UPDATE_GROUPS
} from "../../../core/services/store/users.module";
import Swal from "sweetalert2";

export default {
  data: () => ({
    valid: true,
    dialog: false
  }),
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  methods: {
    refresh: function() {
      this.dialog = true;
      this.$store.dispatch(PULL_USER, this.id);
    },
    closeDialog() {
      this.dialog = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch(UPDATE_GROUPS, { id: this.id, groups: this.groups })
          .then(() => {
            this.dialog = false;
            this.$store.dispatch(PULL_USERS);
            Swal.fire("Updated!", "", "success");
          });
      }
    }
  },
  computed: {
    groups() {
      return {
        1: this.model.user_groups.some(item => item.id == 1),
        2: this.model.user_groups.some(item => item.id == 2),
        3: this.model.user_groups.some(item => item.id == 3),
        4: this.model.user_groups.some(item => item.id == 4),
        5: this.model.user_groups.some(item => item.id == 5)
      };
    },
    ...mapState({
      message: state => state.message.message,
      model: state => state.users.model
    })
  },
  beforeCreate() {
    this.refresh();
  }
};
</script>
