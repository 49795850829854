<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
      >
        <span class="svg-icon svg-icon-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="9" cy="15" r="6"></circle>
              <path
                d="M8.8012943,7.00241953 C9.83837775,5.20768121
														11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10
														C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057
														C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722
														13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,
														7.00241953 Z"
                fill="#000000"
                opacity="0.3"
              ></path>
            </g>
          </svg>
          <!--end::Svg Icon--> </span
        >Invite user</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Invite user to {{ currentProject.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  :class="`alert alert-${message.color}`"
                  role="alert"
                  v-if="message.show"
                >
                  {{ message.text }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  required
                  label="E-mail"
                  ref="f_mail"
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
                <small>*indicates required field</small>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="dialog = false">Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary dark large"
            ref="submit"
            @click="save"
            :loading="this.loading"
            >Invite
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { INVITE_USER, PULL_USERS } from "@/core/services/store/users.module";
import Swal from "sweetalert2";

export default {
  data: () => ({
    valid: true,
    dialog: false,
    email: ""
  }),
  methods: {
    refresh: function() {
      this.email = "";
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(INVITE_USER, { email: this.email }).then(() => {
          this.dialog = false;
          this.$store.dispatch(PULL_USERS);
          Swal.fire("Added!", "", "success");
        });
      }
    }
  },
  computed: {
    ...mapGetters(["currentProject"]),
    ...mapState({
      errors: state => state.users.errors,
      message: state => state.users.message,
      hasMessage: state => state.users.hasMessage,
      loading: state => state.users.loading
    })
  },
  beforeCreate() {
    this.email = "";
  }
};
</script>
