<template>
  <section v-if="!disabled">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                Users
                <span class="d-block text-muted pt-2 font-size-sm"
                  >Manage User accounts and User roles.</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Button-->

              <v-flex class="mr-2">
                <invite_user />
              </v-flex>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="users"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    :custom-filter="customSearch"
                    :custom-sort="customSort"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          placeholder="Search user"
                          label="Search"
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>
                    <template v-slot:item.photo="{ item }">
                      <span class="symbol symbol-50 symbol-light-success">
                        <img
                          v-if="item.image_src"
                          alt="Pic"
                          :src="item.image_src"
                        />
                        <span
                          v-else-if="item.name"
                          class="symbol-label font-size-h5 font-weight-bold"
                        >
                          {{ item.name.charAt(0).toUpperCase() }}
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.full_name="{ item }"
                      ><strong class="text-gray-800 mb-1"
                        >{{ item.name }} {{ item.surname }}</strong
                      ><br />{{ item.user_email }}
                    </template>
                    <template v-slot:item.role="{ item }"
                      >{{ item.company_name }}<br />{{ item.job_position }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        v-if="
                          item.status === 'Invited' ||
                            item.status === 'Cooperate'
                        "
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                      <user_edit v-if="item.super_admin == '0'" :id="item.id" />
                    </template>
                    <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize">
                        Reset
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-else>Not autorized access</section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { PULL_USERS, REMOVE_USER } from "@/core/services/store/users.module";
import invite_user from "@/view/pages/users/invite_user";
import user_edit from "@/view/pages/users/user_authorizations";
import Swal from "sweetalert2";

export default {
  components: { invite_user, user_edit },
  data() {
    return {
      search: "",
      headers: [
        { text: "Photo", value: "photo", sortable: false },
        { text: "FullName", value: "full_name" },
        { text: "Phone", value: "user_telephone", sortable: false },
        { text: "Role", value: "role", sortable: false },
        { text: "Authorizations", value: "user_groups" },
        { text: "status", value: "status" },
        { text: "Last Joined", value: "joined" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      options: {}
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  methods: {
    customSearch(value, search, item) {
      return Object.values(item).some(
        v =>
          v &&
          v
            .toString()
            .toLowerCase()
            .includes(search)
      );
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index.toString() === "full_name") {
          if (isDesc.toString() === "false") {
            return a.name < b.name ? -1 : 1;
          } else {
            return b.name < a.name ? -1 : 1;
          }
        }
      });
      return items;
    },
    deleteItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are remove ${item.name} ${item.surname} from this project. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cut off!"
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch(REMOVE_USER, {
              id: item.id,
              user_email: item.user_email
            })
            .then(() => {
              this.$store.dispatch(PULL_USERS);
              Swal.fire("Removed!", "", "success");
            });
        }
      });
    },
    reloadData() {
      this.$store.dispatch(PULL_USERS);
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapGetters(["currentProject"]),
    ...mapState({
      errors: state => state.users.errors,
      message: state => state.users.message,
      hasMessage: state => state.users.hasMessage,
      users: state => state.users.users,
      loading: state => state.users.loading,
      disabled: state => state.users.disabled,
      collection: function data() {
        this.$store.dispatch(PULL_USERS);
        return true;
      }
    })
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
  }
};
</script>
